import TKModuleFactory from "@tk/utilities/tk.module.factory";
import TKManager from "@tk/basiscs/tk.manager";
import TKHeader from "@tk/components/tk.header";
import TKNavigationSidebar from "@tk/components/tk.navigation.sidebar";
import TKViewport from "@tk/basiscs/tk.viewport";
import TKSearch from "@tk/controls/tk.search";
import TKFormElement from "@tk/controls/tk.form.element";
import TKFormValidator from "@tk/controls/tk.form.validator";
import TKFormZipCity from "@tk/controls/tk.form.zipcity";
import TKTabsTab from "@tk/controls/tk.tabs.tab";
import TKMinibasket from "@tk/components/tk.minibasket";
import TKTabsAccordion from "@tk/controls/tk.tabs.accordion";
import TKCustomNav from "./components/tk.custom.nav";

import "@scss/modules/verification.scss";

export default class Verification extends TKModuleFactory {
    constructor() {
        super([
            { name: "tk-manager", constructor: TKManager },
            { name: "tk-viewport", constructor: TKViewport },
            { name: "tk-header", constructor: TKHeader },
            { name: "tk-nav-sidebar", constructor: TKNavigationSidebar },
            { name: "tk-search", constructor: TKSearch },
            { name: "tk-form-zip-city", constructor: TKFormZipCity },
            { name: "tk-form-element", constructor: TKFormElement },
            { name: "tk-form-validator", constructor: TKFormValidator },
            { name: "tk-tabs-tab", constructor: TKTabsTab },
            { name: "tk-minibasket", constructor: TKMinibasket },
            { name: "tk-tabs-accordion", constructor: TKTabsAccordion },
            { name: "tk-custom-nav", constructor: TKCustomNav },
        ]);
    }
}

// eslint-disable-next-line no-new
new Verification();
