import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKCustomNav extends TKCustomElementFactory {
    navigationWrapper?: HTMLElement;
    wrapper?: HTMLElement;
    openButton?: HTMLElement;
    closeButton?: HTMLElement;

    sidebarToggle?: HTMLButtonElement;
    sidebarToggleSelector: string;
    sidebarToggleCss:string;

    desktopView?: string;
    mobileView?: string;

    isMobile: boolean;
    desktopMinWidth: number;
    animationClassAdded : boolean = false;
    activeNavItemClass : string = 'tk-navigation__item--active';
    startNavItemUrl : string = 'start';

    constructor() {
        super();

        this.navigationWrapper = document.querySelector('[data-tk-nav-wrapper]') || undefined;
        this.wrapper = this.querySelector('[data-tk-wrapper]') || undefined;
        this.openButton = document.querySelector(`[data-tk-nav-open="${this.id}"]`) || undefined;
        this.closeButton = document.querySelector(`[data-tk-nav-close="${this.id}"]`) || undefined;

        this.sidebarToggleSelector = 'data-tk-nav-sidebar';
        this.sidebarToggleCss = 'tk-navigation--sidebar-visible';
        this.sidebarToggle = this.querySelector(`[${this.sidebarToggleSelector}]`) || undefined;

        this.desktopMinWidth = Number(this.getAttribute('data-tk-desktop-min-width')) || 1200;
        this.isMobile = window.viewport.clientWidth < this.desktopMinWidth;
    }

    connectedCallback(): void {
        if (!this.sidebarToggle) throw new Error(`TKCustomNav: Sidebar toggle button ${this.sidebarToggleSelector} is missing!`);

        this.setActiveNavElement();
        this.registerViewportListener();
        this.registerButtonListener();
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        const onSidebarToggle = this.toggleSidebar.bind(this);

        this.pushListener({ event: 'tk-viewport-resize', element: window.viewport, action: onViewportResize });
        this.pushListener({ event: 'click', element: this.sidebarToggle!, action: onSidebarToggle });
    }

    toggleSidebar() {
        this.classList.toggle(this.sidebarToggleCss);
    }

    setActiveNavElement(): void {
        const url : URL | undefined = new URL(window.location.href);
        if (!url) return;
        const path = url.pathname;

        // Dashboard start page
        if (path == '/') {
            const activeItem = this.navigationWrapper?.querySelector(`[data-tk-nav-url="${this.startNavItemUrl}"]`);

            if (!activeItem) return;
            activeItem.classList.add(this.activeNavItemClass);
            return;
        }

        // Split the path by '/' and remove any empty segments
        const segments = path.split('/').filter(segment => segment);

        // Use the second segment (if it exists) as the active selector, removing file extension if present
        if (segments.length < 2) return;
        const activeSelector = segments[1].split('.')[0];
        const activeItem = this.navigationWrapper?.querySelector(`[data-tk-nav-url="${activeSelector}"]`);

        if (!activeItem) return;
        activeItem.classList.add(this.activeNavItemClass);
    }

    handleViewportResize(event: Event): void {
        const customEvent = event as CustomEvent;
        this.isMobile = customEvent.detail.width < this.desktopMinWidth;

        if (!this.isMobile) {
            this.closeNavigation();
        }
    }

    registerButtonListener(): void {
        this.openButton?.addEventListener('click', this.openNavigation.bind(this));
        this.closeButton?.addEventListener('click', this.closeNavigation.bind(this));
    }

    openNavigation(): void {
        if (!this.animationClassAdded) {
            this.navigationWrapper?.classList.add('viewport__navigation--animated');
            this.animationClassAdded = true;
        }

        this.navigationWrapper?.classList.add('viewport__navigation--open');
    }

    closeNavigation(): void {
        this.navigationWrapper?.classList.remove('viewport__navigation--open');
    }
}
