export default class TKModuleFactory {
    customElementList: CustomElementList[];

    constructor(list: CustomElementList[]) {
        this.customElementList = list;
        this.init();
    }

    init(): void {
        this.customElementList.forEach((item) => {
            !customElements.get(item.name) && customElements.define(item.name, item.constructor);
        });
    }
}
