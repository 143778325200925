import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

interface CustomElementAccordionIcon {
    iconAdd: string;
    iconRemove: string;
}

export default class TKTabsAccordion extends TKCustomElementFactory {
    activeClassName: string;
    accordionHead?: HTMLDivElement;
    accordionBody?: HTMLDivElement;
    accordionIcon?: HTMLElement;
    triggerArea?: Element;
    accordionIcons: CustomElementAccordionIcon;
    isActive: boolean;
    isHeaderLink?: boolean;

    constructor() {
        super();

        this.accordionHead = this.querySelector('[data-tk-accordion-head]') || undefined;
        this.accordionBody = this.querySelector('[data-tk-accordion-body]') || undefined;
        this.accordionIcon = this.querySelector('[data-tk-accordion-icon]') || undefined;
        this.accordionIcons = {
            iconAdd: this.getAttribute('data-tk-icon-open') || 'tk-icon-add',
            iconRemove: this.getAttribute('data-tk-icon-collapse') || 'tk-icon-remove',
        };
        this.isActive = this.hasAttribute('data-tk-accordion-is-active');
        this.isHeaderLink = this.accordionHead && this.accordionHead.hasAttribute('data-tk-accordion-link');
        this.activeClassName = this.getAttribute('data-tk-active-class-name') || 'tk-tabs-accordion--active';
    }

    connectedCallback(): void {
        if (
            (!this.accordionHead
            || !this.accordionBody
            || !this.accordionIcon)
            && !this.isHeaderLink
        ) throw new Error('Accordion: Elements are missing!');

        this.setAccordionState(this.isActive);
        this.registerListener();
    }

    registerListener(): void {
        const eventHandler = this.toggleEvent.bind(this);
        this.pushListener({ event: 'click', element: this.accordionHead!, action: eventHandler });
    }

    toggleEvent(): void {
        this.toggleMarkupElements();
    }

    toggleMarkupElements(): void {
        this.classList.toggle(this.activeClassName);
        this.accordionIcon?.classList.toggle(this.accordionIcons.iconAdd);
        this.accordionIcon?.classList.toggle(this.accordionIcons.iconRemove);
    }

    setAccordionState(isActive: boolean): void {
        if (isActive) {
            this.classList.toggle(this.activeClassName);
            this.accordionIcon?.classList.add(this.accordionIcons.iconRemove);
        } else {
            this.accordionIcon?.classList.add(this.accordionIcons.iconAdd);
        }
    }
}
