import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { fetchRequest } from '@tk/utilities/tk.fetch';
import { formErrorMessages, formExpandErrorMessages } from '@tk/utilities/tk.messages';

type FieldElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface ValidityMessages {
    key: keyof ValidityState;
    value: string;
}

interface Validation {
    element: FieldElement;
    wrapper: Element;
    state?: ValidityState;
    messageElement?: HTMLSpanElement;
    messages: ValidityMessages[];
}

interface ValidityItem {
    key: keyof ValidityState;
    value: boolean;
}

interface ValidationResponse {
    isValid: boolean;
}

enum matchType {
    Some = 'SOME',
    Full= 'FULLMATCH',
}

export default class TKFormValidator extends TKCustomElementFactory {
    form?: HTMLFormElement;
    validationList: Validation[];
    fieldWrapperSelector: string;
    formErrorClassName: string;
    fieldHintClassName: string;
    shouldValidateInline: boolean;
    passwordStrengthMeter?: HTMLElement;
    passwordNewElement?: HTMLElement;
    passwordRepeatElement?: HTMLElement;
    passwordValidClassName: string;
    passwordInvalidClassName: string;

    constructor() {
        super();

        this.form = this.querySelector('form') || undefined;
        this.validationList = [];
        this.fieldWrapperSelector = this.getAttribute('data-tk-field-wrapper-selector') || '[data-tk-field-wrapper]';
        this.formErrorClassName = this.getAttribute('data-tk-form-error-class-name') || 'tk-form--error';
        this.fieldHintClassName = this.getAttribute('data-tk-field-hint-class-name') || 'tk-form__hint';
        this.shouldValidateInline = this.hasAttribute('data-tk-should-validate-inline');
        this.passwordStrengthMeter = this.querySelector('[data-tk-password-strength-meter]') || undefined;
        this.passwordNewElement = this.querySelector('[data-tk-password-new]') || undefined;
        this.passwordRepeatElement = this.querySelector('[data-tk-password-repeat]') || undefined;
        this.passwordValidClassName = this.getAttribute('data-tk-password-valid-class-name') || 'tk-form--valid';
        this.passwordInvalidClassName = this.getAttribute('data-tk-password-valid-class-name') || 'tk-form--invalid';
    }

    connectedCallback(): void {
        if (!this.form) return;

        this.setNoValidateForm();
        this.searchFieldElements();
        this.registerSubmitListener();
        this.registerPasswordListener();
        this.shouldValidateInline && this.registerFieldValidationListener();
    }

    setNoValidateForm() {
        if (!this.form) return;
        this.form.noValidate = true;
    }

    searchFieldElements(): void {
        const formElements: NodeListOf<FieldElement> = this.querySelectorAll('input, select, textarea');
        const formElementList = Array.from(formElements);
        const filteredFormElements = formElementList.filter((element) => !element.disabled);
        filteredFormElements.forEach((element) => {
            const wrapper = element.closest(this.fieldWrapperSelector);
            wrapper
                && this.validationList.push({
                    element,
                    wrapper,
                    messages: TKFormValidator.generateValidityMessages(element),
                });
        });
    }

    registerSubmitListener() {
        if (!this.form) return;
        const onSubmitHandler = this.validateForm.bind(this);
        this.pushListener({ event: 'submit', element: this.form, action: onSubmitHandler });
    }

    registerPasswordListener() {
        if (!(this.passwordStrengthMeter && this.passwordNewElement && this.passwordRepeatElement)) return;

        const onValidatePassword = this.validatePassword.bind(this);
        this.pushListener({ event: 'input', element: this.passwordNewElement, action: onValidatePassword });
        this.pushListener({ event: 'input', element: this.passwordRepeatElement, action: onValidatePassword });
    }

    registerFieldValidationListener() {
        this.validationList.forEach((item) => {
            const onValidation = this.validateField.bind(this, item);
            this.pushListener({ event: 'blur', element: item.element, action: onValidation });
            this.pushListener({ event: 'input', element: item.element, action: onValidation });
        });
    }

    async validateByAPI(): Promise<boolean> {
        const validationList: boolean[] = [];
        const fieldElements = this.querySelectorAll('[data-tk-field-check-api]');
        if (fieldElements.length === 0) return true;
        const apiPromises = Array.from(fieldElements).map(async (element) => {
            const apiURL = element.getAttribute('data-tk-field-check-api');
            const input = element.querySelector('input');
            if (!input || !apiURL) return true;
            const response = await TKFormValidator.fetchExistAPI<ValidationResponse>(apiURL, input.value);
            const validationItem = this.validationList.find((item) => item.wrapper === element);
            const { isValid } = response.dataAsJson;
            if (!isValid && validationItem) {
                this.removeMessage(validationItem);
                this.createElementMessage(
                    validationItem,
                    formExpandErrorMessages.existigName,
                );
            }
            return isValid;
        });
        const results = await Promise.all(apiPromises);
        validationList.push(...results);
        return validationList.every((item) => item);
    }

    static async fetchExistAPI<T>(url: string, value: string): Promise<TKResponse<T>> {
        return new Promise((resolve) => {
            fetchRequest<T>({
                requestURL: url,
                payload: {
                    value,
                },
                resolveHandler: (response: TKResponse<T>) => {
                    resolve(response);
                },
            });
        });
    }

    async validateForm(event: Event) {
        this.validationList.forEach((item) => {
            this.validateField(item);
            event.preventDefault();
        });

        const isValid = await this.validateByAPI();

        if (
            this.validationList.every((item) => item.element.validity.valid)
            && (!this.passwordStrengthMeter || this.checkPasswordStengthMeter())
            && this.checkPasswordMatching()
            && isValid
        ) {
            this.form?.submit();
        }
    }

    async isFormValid(event: Event) {
        this.validationList.forEach((item) => {
            this.validateField(item);
            event.preventDefault();
        });

        const isValid = await this.validateByAPI();

        return (
            this.validationList.every((item) => item.element.validity.valid)
            && (!this.passwordStrengthMeter || this.checkPasswordStengthMeter())
            && this.checkPasswordMatching()
            && !isValid
        );
    }

    validateField(item: Validation) {
        this.removeMessage(item);
        if (!item.element.validity.valid) {
            this.showMessage(item);
        }
    }

    showMessage(item: Validation) {
        const validityList = TKFormValidator.createValidityDictionary(item.element);
        const invalidMessage = validityList.find((validity) => validity.value);
        const message = invalidMessage
            ? item.messages.find((message) => message.key === invalidMessage.key)?.value || ''
            : item.element.validationMessage;

        this.createElementMessage(item, message);
    }

    createElementMessage(item: Validation, message: string) {
        const spanElement = document.createElement('span');
        spanElement.classList.add(this.fieldHintClassName);
        spanElement.textContent = TKFormValidator.replacePlaceholders(message, item.element);
        item.messageElement = spanElement;
        item.wrapper.classList.add(this.formErrorClassName);
        item.wrapper.insertAdjacentElement('beforeend', spanElement);
    }

    removeMessage(item: Validation) {
        const spanElement = item.messageElement;
        if (!spanElement) return;
        spanElement.remove();
        item.wrapper.classList.remove(this.formErrorClassName);
    }

    static createValidityDictionary(element: FieldElement) {
        const { validity } = element;
        const validityList: ValidityItem[] = [];
        Object.keys(validity).forEach((key) => {
            const validityItem: ValidityItem = {
                key: key as keyof ValidityState,
                value: Boolean(validity[key as keyof ValidityState]),
            };
            validityList.push(validityItem);
        });
        return validityList;
    }

    static generateValidityMessages(element: FieldElement): ValidityMessages[] {
        const list: ValidityMessages[] = [];
        const attributeList = element.getAttributeNames().filter((name) => name.startsWith('data-tk-error-msg-'));
        attributeList.forEach((key) => {
            const keyFormatted = key.replace(/-./g, (x) => x[1].toUpperCase()) as keyof ValidityState;
            const value = element.getAttribute(key);
            value
                && list.push({
                    key: keyFormatted,
                    value,
                });
        });

        if (window.opacc.tkFormValidityMessages) {
            const messages = window.opacc.tkFormValidityMessages;
            Object.entries(messages).forEach((entry) => {
                const [key, value] = entry;
                const foundMessage = list.filter((item) => item.key === key);
                !foundMessage.length
                    && list.push({
                        key: key as keyof ValidityState,
                        value,
                    });
            });
        }

        return list;
    }

    static replacePlaceholders(value: string, element: FieldElement): string {
        const dictionary: Record<string, string> = {
            '{minLength}': element.getAttribute('minlength') || '',
            '{maxLength}': element.getAttribute('maxlength') || '',
            '{length}': element.value.length.toString() || '',
            '{step}': element.getAttribute('step') || '',
            '{min}': element.getAttribute('min') || '',
            '{max}': element.getAttribute('max') || '',
            '{currentValue}': element.value,
        };
        const re = new RegExp(Object.keys(dictionary).join('|'), 'gi');
        return value.replace(re, (matched) => dictionary[matched]);
    }

    checkPasswordStengthMeter() {
        const patterns = this.getPasswordPatterns();
        return patterns.every((item) => item.element?.classList.contains(this.passwordValidClassName));
    }

    checkPasswordMatching(): boolean {
        let isValid: boolean;
        const newPasswordItem = this.validationList.find((item) => item.element === this.passwordNewElement);
        const repeatPasswordItem = this.validationList.find((item) => item.element === this.passwordRepeatElement);
        if (!newPasswordItem || !repeatPasswordItem) return true;
        if (newPasswordItem.element.value !== repeatPasswordItem.element.value) {
            this.removeMessage(repeatPasswordItem);
            this.createElementMessage(
                repeatPasswordItem,
                window.opacc.tkFormPasswordValidityMessages!.passwordNotMatching,
            );
            isValid = false;
        } else {
            isValid = true;
        }
        return isValid;
    }

    validatePassword(event: Event) {
        const target = event.currentTarget as HTMLInputElement;
        const patterns = this.getPasswordPatterns();

        patterns.forEach((item) => {
            const regex = new RegExp(item.pattern || '', item.matchtype === matchType.Full ? 'g' : '');

            const regexpMatch = target.value.match(regex);

            if (item.matchtype === matchType.Some) {
                if (regexpMatch) {
                    item.element?.classList.remove(this.passwordInvalidClassName);
                    item.element?.classList.add(this.passwordValidClassName);
                } else {
                    item.element?.classList.remove(this.passwordValidClassName);
                    item.element?.classList.add(this.passwordInvalidClassName);
                }
            }

            if (item.matchtype === matchType.Full) {
                if (regexpMatch?.length === target.value.length) {
                    item.element?.classList.remove(this.passwordInvalidClassName);
                    item.element?.classList.add(this.passwordValidClassName);
                } else {
                    item.element?.classList.remove(this.passwordValidClassName);
                    item.element?.classList.add(this.passwordInvalidClassName);
                }
            }
        });
    }

    getPasswordPatterns() {
        const characterPatternElement = this.passwordStrengthMeter?.querySelector('[data-tk-pwd-character-pattern]');
        const upperPatternElement = this.passwordStrengthMeter?.querySelector('[data-tk-pwd-upper-pattern]');
        const numberPatternElement = this.passwordStrengthMeter?.querySelector('[data-tk-pwd-number-pattern]');
        const allowedSymbolPatternElement = this.passwordStrengthMeter?.querySelector(
            '[data-tk-pwd-allowed-symbols-pattern]',
        );
        const characterPattern = characterPatternElement?.getAttribute('data-tk-pwd-character-pattern');
        const upperPattern = upperPatternElement?.getAttribute('data-tk-pwd-upper-pattern');
        const numberPattern = numberPatternElement?.getAttribute('data-tk-pwd-number-pattern');
        const allowedSymbolPattern = allowedSymbolPatternElement?.getAttribute('data-tk-pwd-allowed-symbols-pattern');
        return [
            {
                pattern: characterPattern,
                element: characterPatternElement,
                matchtype: matchType.Some,
            },
            {
                pattern: upperPattern,
                element: upperPatternElement,
                matchtype: matchType.Some,
            },
            {
                pattern: numberPattern,
                element: numberPatternElement,
                matchtype: matchType.Some,
            },
            {
                pattern: allowedSymbolPattern,
                element: allowedSymbolPatternElement,
                matchtype: matchType.Full,
            },
        ];
    }
}
