import { fetchRequest, setAsyncUrl } from '@tk/utilities/tk.fetch';
import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { directSearchSuggestMessages } from '@tk/utilities/tk.messages';
import { isOutsideShapes } from '@tk/utilities/tk.shape.detector';

interface ISeachInputField {
    minimum: number;
    value: string;
    valid: boolean;
    hasDangerousString: boolean;
    hasDisallowedChars: boolean;
}
// eslint-disable-next-line no-use-before-define
type FieldElement = HTMLElement | TKSearch;

interface IDOMElement {
    element?: FieldElement;
    className: string;
}

interface ISearchDataRequest {
    type: string;
    inputValue: string;
    filterValue?: string;
    filterAttribute?: string;
}

interface SearchResponse {
    html: string;
}

export default class TKSearch extends TKCustomElementFactory {
    selectorSearch: string = '[data-tk-search]';
    selectorSearchBackButton: string = '[data-tk-search-back]';
    selectorSearchResetButton: string = '[data-tk-search-reset]';
    selectorSearchSubmitButton: string = '[data-tk-search-submit]';
    selectorSearchFilterElement: string = '[data-tk-search-filter]';
    selectorSearchResult: string = '[data-tk-search-result]';
    attributeSearchMinLength: string = '[data-tk-search-min-input-length]';
    activeClassName: string;
    activeResultClassName: string;
    tooShortClassName: string;
    loadingClassName: string;

    directSearchForm?: HTMLFormElement;
    directSearchInputElement?: HTMLInputElement;
    directSearchBackButton?: HTMLButtonElement;
    directSearchResetButton?: HTMLButtonElement;
    directSearchSubmitButton?: HTMLButtonElement;
    directSearchFilterElement?: HTMLSelectElement;
    directSearchInputObj: ISeachInputField = {} as ISeachInputField;
    directSearchResult?: HTMLElement;
    directSearchMinLength: number;
    timerID: number = 0;

    searchTermAsUrlParam: boolean = true;
    disallowedCharacters: string = '';
    disallowedCharactersRegex: RegExp = /[^a-zA-Z0-9 �����������������������������������������������������',-_.]+/;

    constructor() {
        super();
        this.directSearchForm = this.querySelector(this.selectorSearch) || undefined;
        this.directSearchBackButton = this.querySelector(this.selectorSearchBackButton) || undefined;
        this.directSearchResetButton = this.querySelector(this.selectorSearchResetButton) || undefined;
        this.directSearchSubmitButton = this.querySelector(this.selectorSearchSubmitButton) || undefined;
        this.directSearchFilterElement = this.querySelector(this.selectorSearchFilterElement) || undefined;
        this.directSearchResult = this.querySelector(this.selectorSearchResult) || undefined;
        this.directSearchMinLength = Number(this.getAttribute(this.attributeSearchMinLength)) || 3;
        this.activeClassName = this.getAttribute('data-tk-active-class-name') || 'tk-search--active';
        this.activeResultClassName = (
            this.getAttribute('data-tk-active-result-class-name') || 'tk-search--has-data'
        );
        this.tooShortClassName = this.getAttribute('data-tk-too-short-class-name') || 'tk-search__result--too-short';
        this.loadingClassName = this.getAttribute('data-tk-loading-class-name') || 'tk-search__result--loading';

        if (!this.directSearchForm) {
            throw new Error('TK: Missing Direct-Search');
        }

        this.directSearchInputElement = this.directSearchForm.querySelector('input') || undefined;

        if (!this.directSearchInputElement) {
            throw new Error('TK: Missing Direct-Search-Input');
        }

        if (!this.directSearchResult) {
            throw new Error('TK: Missing Direct-Search Result Wrapper');
        }
        TKSearch.replaceToActionUrl();
    }

    connectedCallback(): void {
        this.registerListener();
    }

    registerListener(): void {
        if (this.directSearchInputElement) {
            const keyUpEventHandler = this.getSuggest.bind(this);
            const setClassEventHandler = this.setActiveClass.bind(this);
            const focusOutEventHandler = this.focusOut.bind(this);
            this.pushListener({ event: 'input', element: this.directSearchInputElement, action: keyUpEventHandler });
            this.pushListener({
                event: 'focusin',
                element: this.directSearchInputElement,
                action: setClassEventHandler,
            });
            this.pushListener({ event: 'click', element: window, action: focusOutEventHandler });
        }

        if (this.directSearchBackButton) {
            const navigateBackEventHandler = this.navigateBack.bind(this);
            this.pushListener({
                event: 'click',
                element: this.directSearchBackButton,
                action: navigateBackEventHandler,
            });
        }

        if (this.directSearchResetButton) {
            const resetEventHandler = this.resetSearch.bind(this);
            this.pushListener({
                event: 'click',
                element: this.directSearchResetButton,
                action: resetEventHandler,
            });
        }

        if (this.directSearchFilterElement) {
            const selectboxEventHandler = this.getSuggest.bind(this);
            this.pushListener({
                event: 'change',
                element: this.directSearchFilterElement,
                action: selectboxEventHandler,
            });
        }
    }

    setActiveClass(): void {
        this.classList.add(this.activeClassName);
    }

    focusOut(event: MouseEvent): void {
        const fieldElement = this.directSearchInputElement;
        const resultElement = this.directSearchResult;
        if (!fieldElement || !resultElement) return;
        const fieldElementRect = fieldElement.getBoundingClientRect();
        const resultRect = resultElement.getBoundingClientRect();
        const rectangles = [
            { rectangle: fieldElementRect },
            { rectangle: resultRect },
        ];
        if (isOutsideShapes(event, rectangles)) {
            this.classList.remove(this.activeClassName);
        }
    }

    setProgressBar(): void {
        const progressBarTpl: string = `
        <div class="tk-progress tk-progress--infinite tk-progress--breakout">
            <div class="tk-progress__bar"></div>
        </div>`;

        this.directSearchResult?.insertAdjacentHTML('beforeend', progressBarTpl);
    }

    setMessageTooShort(): void {
        const msgTpl: string = `
        <div class="tk-message tk-message--info tk-message--breakout">
            <span class="tk-message__icon">
                <i class="tk-icon-info"></i>
            </span>
            <span class="tk-message__label">
                ${directSearchSuggestMessages.messageTooShort.text.replace('%1', String(this.directSearchMinLength))}
            </span>
        </div>`;

        this.directSearchResult?.insertAdjacentHTML('beforeend', msgTpl);
    }

    getSuggest(): void {
        if (!this.directSearchResult) return;

        this.directSearchInputObj = this.validateSearchInput();
        this.modifyDOM();

        if (!this.directSearchInputObj.valid) {
            this.classList.add(this.activeResultClassName);
            this.setMessageTooShort();
            return;
        }

        this.resetResultList();

        this.timerID && clearTimeout(this.timerID);
        this.timerID = window.setTimeout(() => {
            // append Progress-Bar
            this.setProgressBar();

            const searchData = this.setDataForRequest();
            const data: Record<string, string> = Object.fromEntries(Object.entries(searchData) as [string, string][]);

            const url = setAsyncUrl();
            fetchRequest({
                requestURL: url,
                resolveHandler: this.getDirectSearchSuggestDataFromResult.bind(this),
                payload: data,
            });
        }, 500);
    }

    setDataForRequest(): ISearchDataRequest {
        const retVal: ISearchDataRequest = {
            type: 'directsearchsuggest',
            inputValue: encodeURIComponent(this.directSearchInputObj.value),
            filterAttribute: this.directSearchFilterElement?.dataset.tkSearchFilterAttribute,
            filterValue: this.directSearchFilterElement?.value,
        };

        if (!this.directSearchFilterElement) {
            delete retVal.filterAttribute;
            delete retVal.filterValue;
        }

        return retVal;
    }

    getDirectSearchSuggestDataFromResult(response: TKResponse<SearchResponse>): void {
        if (!this.directSearchResult) {
            return;
        }

        this.classList.add(this.activeResultClassName);
        this.directSearchResult.innerHTML = response.dataAsJson.html;
    }

    modifyDOM(): void {
        const classListObj = TKSearch.clearClassListObj();

        classListObj.push(
            {
                element: this,
                className: this.activeClassName,
            },
            {
                element: this,
                className: this.activeResultClassName,
            },
        );

        if (this.directSearchInputObj.valid) {
            TKSearch.addClassList(classListObj);
        } else {
            this.resetResultList();
        }
    }

    static clearClassListObj(): IDOMElement[] {
        const classListObj: IDOMElement[] = [];
        return classListObj;
    }

    static addClassList(items: IDOMElement[]): void {
        items.forEach((item: IDOMElement) => {
            item.element?.classList.add(item.className);
        });
    }

    static removeClassList(items: IDOMElement[]): void {
        items.forEach((item: IDOMElement) => {
            item.element?.classList.remove(item.className);
        });
    }

    resetResultList(): void {
        this.directSearchResult?.replaceChildren();
    }

    navigateBack(): void {
        const classListObj = TKSearch.clearClassListObj();
        classListObj.push({
            element: this,
            className: this.activeClassName,
        });

        TKSearch.removeClassList(classListObj);
    }

    resetSearch(): void {
        const classListObj = TKSearch.clearClassListObj();
        classListObj.push(
            {
                element: this,
                className: this.activeClassName,
            },
            {
                element: this,
                className: this.activeResultClassName,
            },
        );
        this.resetResultList();
        TKSearch.removeClassList(classListObj);
        this.directSearchForm?.reset();
    }

    static replaceToActionUrl(): void {
        const actionUrl = localStorage.getItem('searchActionUrl')
            ? new URL(localStorage.getItem('searchActionUrl') as string)
            : undefined;

        if (actionUrl && actionUrl.pathname === window.location.pathname) {
            window.history.replaceState(null, 'searchActionUrl', actionUrl);
        }
        if (actionUrl) {
            localStorage.removeItem('searchActionUrl');
        }
    }

    validateSearchInput(): ISeachInputField {
        const value = this.directSearchInputElement?.value.trim() || '';
        const minLength = Number(this.getAttribute('data-tk-search-min-input-length') || 3);
        const hasDangerousString = value?.includes('<');
        const hasDisallowedChars = this.hasDisallowedCharacters(value);

        return {
            minimum: minLength,
            value,
            valid: value.length >= minLength && !hasDangerousString && !hasDisallowedChars,
            hasDangerousString,
            hasDisallowedChars,
        };
    }

    hasDisallowedCharacters(value: string): boolean {
        if (value === '') {
            this.disallowedCharacters = '';
            return false;
        }

        let disallowedCharactersRegex: RegExp;
        if (window.opacc.tkSearchTermModifier) {
            disallowedCharactersRegex = new RegExp(window.opacc.tkSearchTermModifier.disallowCharacters);
        } else {
            disallowedCharactersRegex = this.disallowedCharactersRegex;
        }
        const matches = value.match(disallowedCharactersRegex);
        if (matches && matches.length > 0) {
            const character = matches.at(0);
            if (character) {
                this.disallowedCharacters = character;
                return true;
            }
            return false;
        }

        this.disallowedCharacters = '';
        return false;
    }
}
