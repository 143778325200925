export interface Listener<T extends Event = Event> {
    event: keyof HTMLElementEventMap | TKCustomEventMap;
    element: EventTarget;
    action: (event: T) => void;
}

export default class TKCustomElementFactory extends HTMLElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listeners: Listener<any>[];

    constructor() {
        super();
        this.listeners = [];
    }

    // eslint-disable-next-line class-methods-use-this
    connectedCallback(): void {}

    /**
     * If the custom element is getting removed of the dom, all listeners
     * are getting removed of the element.
     */
    disconnectedCallback(): void {
        this.listeners.forEach((item) => {
            item.element.removeEventListener(item.event, item.action);
        });
    }

    /**
     * Here you can push a listener object directly to the listener array
     *
     * @param {Listener} {}
     */
    pushListener<T extends Event>({ event, element, action }: Listener<T>): void {
        this.listeners.push({ event, element, action });
        element.addEventListener(event, action as EventListener);
    }

    /**
     * Removes the given element from the listener array and removes the
     * EventListener from the element and returns the listener object.
     * If the element was not found, undefined is returned.
     *
     * @param {Element} element
     * @returns {Listener | undefined}
     */
    removeListener(element: EventTarget): Listener | undefined {
        const foundElement = this.listeners.find((item) => item.element === element);
        if (!foundElement) return;
        foundElement?.element.removeEventListener(foundElement.event, foundElement.action);
        const newList = this.listeners.filter((item) => item.element !== element);
        this.listeners = newList;
        return foundElement;
    }
}
